import React from "react"
import { Button, Col, List, Row, Typography } from "antd"
import { EnvironmentOutlined, GlobalOutlined } from "@ant-design/icons"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Shell from "../../components/Shell/Shell"
import Hero from "../../components/SharpHero/SharpHero"
import BookingBar from "../../components/BookingBar/BookingBar"
import { darken, lighten } from "../../utils/color"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"
import {
  clickablePhoneNumber,
  prettifyPhoneNumber,
} from "../../utils/phoneNumbers"

const description = `Guests will enjoy all that downtown Asheville has to offer from dining options, shopping, and much more when staying at the Residences at Biltmore all suite hotel.`

const mapsLink = address =>
  `https://google.com/maps/place/${address.replace(/ /g, "+")}`

const tan = `#F2F0E9`

const centeredRow = {
  align: `middle`,
  justify: `center`,
  type: `flex`,
}

const normalCol = {
  xs: 23,
  sm: 22,
  md: 20,
  lg: 18,
  xl: 16,
  xxl: 14,
}

const cardStyle = {
  background: lighten(tan, 7),
  border: `1px solid ${darken(tan, 2)}`,
  borderRadius: 4,
  boxShadow: `0 4px 8px rgba(0,0,0,0.1)`,
  margin: `24px auto`,
  padding: 16,
}

const Image = ({ asset, width }) =>
  asset ? (
    <Img
      alt={asset.description}
      fluid={asset.fluid}
      style={{ height: `100%`, margin: `auto`, maxHeight: 350, width }}
    />
  ) : null

const Title = ({ name }) => (
  <span style={{ fontSize: 24, marginBottom: 0 }}>{name}</span>
)

const ItemMeta = ({ address, phone }) => (
  <>
    {phone ? (
      <a href={`tel:${clickablePhoneNumber}`}>{prettifyPhoneNumber(phone)}</a>
    ) : null}
    {phone ? <br /> : null}
    <Typography.Text copyable style={{ color: `rgba(0,0,0,0.45)` }}>
      {address}
    </Typography.Text>
  </>
)

const Content = ({ body }) => (
  <>
    <div
      dangerouslySetInnerHTML={{
        __html: body.childMarkdownRemark.html,
      }}
    />
    <div style={{ height: 12, width: `100%` }} />
  </>
)

export default ({ data: { hero, activites }, location }) => {
  const [imgWidth, setImgWidth] = React.useState(275)
  const { width } = React.useContext(MediaContext)
  const itemRef = React.useRef(null)

  React.useEffect(() => {
    if (!itemRef || !itemRef.current) return

    if (width > 576) setImgWidth(275)
    else {
      const colWidth = itemRef.current.getBoundingClientRect().width
      if (colWidth) setImgWidth(colWidth - 50)
    }
  }, [width])

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Asheville Area Attractions | Activities - The Residences at Biltmore"
        url={location.href}
      />
      <Hero contentfulAsset={hero} height="40vh" />
      <BookingBar />
      <Row {...centeredRow} style={{ marginTop: 32 }}>
        <Col {...normalCol} style={{ textAlign: `center` }}>
          <Typography.Title>Activities</Typography.Title>
        </Col>
        <Col {...normalCol}>
          Asheville, North Carolina is home to some of the most unique people,
          awe-inspiring landscapes, and culinary and beverage experiences in the
          country. The Residences at Biltmore all-suite hotel is conveniently
          located in the heart of this vibrant and eclectic city. On this page,
          you’ll find a sampling of some of the exciting activities, events, and
          notable sites you don’t want to miss. If you’d like to know more about
          any of these great opportunities or schedule a reservation or tour,
          our free concierge service can handle all of that for you!
        </Col>
        <Col {...normalCol} style={{ marginTop: 32 }}>
          <div
            style={{
              alignItems: `center`,
              display: `flex`,
              justifyContent: `center`,
              width: `100%`,
            }}
          >
            <iframe
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              height="315"
              src="https://www.youtube.com/embed/aF5Ipu0DIyw"
              title="Asheville, N.C.: Return Again - YouTube"
              width="560"
            ></iframe>
          </div>
        </Col>
      </Row>
      <Row {...centeredRow} style={{ marginTop: 32 }}>
        <Col {...normalCol} ref={itemRef}>
          <List
            itemLayout="vertical"
            dataSource={activites.nodes}
            renderItem={(
              { address, description, name, image, phoneNumber, website },
              index
            ) => (
              <List.Item
                actions={[
                  website ? (
                    <a href={website}>
                      <Button
                        block
                        ghost
                        icon={<GlobalOutlined />}
                        type="primary"
                      >
                        Website
                      </Button>
                    </a>
                  ) : null,
                  address ? (
                    <a href={mapsLink(address)}>
                      <Button
                        block
                        ghost
                        icon={<EnvironmentOutlined />}
                        type="primary"
                      >
                        Map
                      </Button>
                    </a>
                  ) : null,
                ]}
                extra={<Image asset={image} width={imgWidth} />}
                key={index}
                style={cardStyle}
              >
                <List.Item.Meta
                  description={
                    <ItemMeta address={address} phone={phoneNumber} />
                  }
                  title={<Title name={name} />}
                ></List.Item.Meta>
                {<Content body={description} />}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "Explore Asheville - Skyline" }) {
      ...HeroFluid
    }
    activites: allContentfulAttraction(
      filter: {
        business: { elemMatch: { name: { eq: "Residences at Biltmore" } } }
      }
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        address
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
          description
          createdAt
        }
        name
        phoneNumber
        website
      }
    }
  }
`
